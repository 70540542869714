/**
 * This react helmt code is adapted from
 * https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet.
 *
 * A great tutorial explaining how to setup a robust version of an
 * SEO friendly react-helmet instance.
 *
 *
 * Use the Helmt on pages to generate SEO and meta content!
 *
 * Usage:
 * <SEO
 *   title={title}
 *   description={description}
 *   image={image}
 * />
 *
 */

import React from 'react'
import Helmet from 'react-helmet'

interface HelmetProps {
  children?: React.ReactChildren
  title: string
  description?: string
  pathname: string
  image?: string
  url?: string
  canonical?: string
  contentType?: string
  published?: string
  updated?: string
  category?: string
  tags?: string
  twitter?: string
  readingTime?: string
}

const seoURL = path => `https://musezade.com${path}`

// Twitter requires https to prepend any paths.
const addHttps = path => {
  if (path.substring(0, 5) === 'https') return path
  return `https:${path}`
}

const seoDescription =
  "Muse Zade- Best IT company in Kathmandu. custom software development."

const getMetaTags = ({
  title,
  description,
  url,
  image,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter,
  readingTime,
}: HelmetProps) => {
  const metaTags = [
    { charset: 'utf-8' },
    {
      'http-equiv': 'X-UA-Compatible',
      content: 'IE=edge',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      name: 'theme-color',
      content: '#000',
    },
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { itemprop: 'image', content: addHttps(image) },
    { name: 'description', content: description },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: 'Muse Zade' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: twitter || 'Musezade' },

    {
      name: 'twitter:image',
      content: addHttps(image),
    },
    { property: 'og:title', content: title },
    { property: 'og:type', content: contentType },
    { property: 'og:url', content: url },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: 'Musezade' },
  ]

  if (published)
    metaTags.push({ name: 'article:published_time', content: published })
  if (updated)
    metaTags.push({ name: 'article:modified_time', content: updated })
  if (category) metaTags.push({ name: 'article:section', content: category })
  if (tags) metaTags.push({ name: 'article:tag', content: tags })

  if (readingTime) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
    metaTags.push({ name: 'twitter:data1', value: readingTime })
  }

  return metaTags
}

const SEO = ({
  children,
  title,
  description = seoDescription,
  pathname,
  canonical,
  image,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter,
  readingTime,
}: HelmetProps) => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      link={[
        {
          rel: 'canonical',
          href: canonical
            ? canonical
            : `https://www.musezade.com/${pathname.replace(/^\/+/g, '')}`,
        },
      ]}
      meta={getMetaTags({
        title,
        description,
        contentType,
        url: seoURL(pathname),
        image,
        published,
        updated,
        category,
        tags,
        twitter,
        readingTime,
      })}
    >
      {children}
    </Helmet>
  )
}

export default SEO
