import React from 'react'
import styled from 'styled-components'

import media from '@styles/media'

const LogoSymbolContainer = styled.div`
  height: 30px;
  width: 23px;

  ${media.phablet`
    height: 25px;
    width: 19.09px;
  `};
`


const calcOpacity = (entering: boolean, top: number): { opacity?: number } => {
  const opacity = Math.abs(top) > 250 ? 0 : 1 - Math.abs(top) / 250

  return entering ? {} : { opacity }
}

const calcTransform = (offset: number): string =>
  `translateY(${offset * 180}px)`

/**
 * An inline SVG for Narative Logo with aria labels
 *
 * @param {String} fill dark or light
 */
const Logo = ({
  fill = '#fff',
  onlySymbol = false,
}: {
  fill?: string
  onlySymbol?: boolean
}) => {
  if (onlySymbol) {
    return (
      <LogoSymbolContainer>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 23 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <title>Muse Zade</title>
<g>
  <text x="50%" y="50%" 
  dominant-baseline="middle" text-anchor="middle"  font-weight="bold" font-family="Verdana" font-size="35" fill="black">µZ</text>
</g>
        </svg>
      </LogoSymbolContainer>
    )
  }

  return (
    <HeadingBackground
  >
    <LargeHeading2>
      µZ
    </LargeHeading2>
    <LargeHeading>
    Muse
    </LargeHeading>
    <LargeHeadingx>
    Zade
    </LargeHeadingx>

  </HeadingBackground>
  )
}


const LargeHeading = styled.p`
  display: inline;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 2.1;
  font-family: ${p => p.theme.fontfamily.serif};
  background: transparent;
  transform: '[{ rotate: '90deg'}]';
  color: transparent;
  margin-left: 10px;
`
const LargeHeadingx = styled.p`
  display: inline;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 2.1;
  font-family: ${p => p.theme.fontfamily.serif};
  background: transparent;
  color: transparent;
`
const LargeHeading2 = styled.p`
  display: inline;
  font-weight: 900;
  font-size: 27px;
  letter-spacing: -0.5px;
  line-height: 2.1;
  max-width: 100px;
  font-family: ${p => p.theme.fontfamily.serif};
  color: black;
  background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, white 1%, #BDA156 10%, #E6BE8A 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 2%, #E6BE8A 25%, #5d4a1f 10.5%, #E6BE8A 1%);
  border-radius: 10px;
`

const HeadingBackground = styled.div`
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  background-repeat: no-repeat;
  background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, white 8%, #BDA156 30%, #E6BE8A 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #E6BE8A 25%, #5d4a1f 62.5%, #E6BE8A 100%);
  color: transparent !important;
  max-width: 1000px;
  z-index: 4;
  
`
export default Logo
